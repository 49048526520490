import React from 'react';
import Blocked from '../components/home/Blocked.jsx';
import AccordionComponent from '../components/home/FAQ';
import MobileForm from '../components/Form/MobileForm';
import Goal from '../components/home/Goal';
import Review from '../components/home/Review';
import Step from '../components/home/Step';
import Index from '../components/home/Index';
import Contacto from './Contact';
import Infort from '../components/home/Infort';
import Servicios from '../components/home/Servicios';
import Ways from '../components/home/Ways';
import Banner from '../components/banner/Banner.jsx';

const Home = () => (
    <>
        <Index />
        <MobileForm />
        <Contacto />
        <Review />
        <Step />
        <Goal />
        <Infort />
        <Blocked />
        <Ways />
        <Servicios />
        <AccordionComponent />
        <Banner />
    </>
);

export default Home;