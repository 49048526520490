import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';



const Review = () => {

  const reviews = [
    {
      name: "ALFONSO SANTACRUZ",
      location: "Envío de USA a Guatemala ",
      review: '"No tuve que gastar tiempo buscando, me contactaron enseguida, excelente el servicio estuvieron en contacto conmigo hasta recibir el vehículo".'
    },
    {
      name: "GRACIELA DE MANRIQUE",
      location: "Envió de Puerto Rico a Detroit, US.",
      review: '"Escogí la empresa que más me gustó y cumplieron de maravilla".'
    },
    {
      name: "MARCELA ORTEGA",
      location: "Envío dentro de USA",
      review: '"Súper confiables y seguros,  el vehículo llegó el día que me dijeron y me permitieron llevar artículos dentro de la cajuela".'
    },
  ];


  return (
    <Container fluid className="container-fluid p-3 degraded-background" id="testimonios">
      {/* Asumiendo que deseas un título encima de tus tarjetas */}
      <Row className="d-flex d-md justify-content-center text-font font-f py-3">
        <Col>
          <h2 className="text-center fw-bold">LOS CLIENTES DICEN</h2>
        </Col>
      </Row>

      <Row className="justify-content-center mx-auto py-3">
        {reviews.map((review, index) => (
          <Col md={4} className="d-flex justify-content-center mb-5" key={index}>
            <Card className="bg-cardreview h-100 rounded-4 custom-card custom-card-body px-3 py-2">
              <Card.Body className="d-flex custom-card-body">
                <div className="mb-4">
                  <img src="./images/rating.svg" className='d-flex' alt="Rating" style={{ width: 'auto', height: 'auto', content: 'center' }} />
                </div>
                <Card.Title className='font-f fw-bold' style={{ color: '#FFCBD6', fontSize: '1.25rem', }}>{review.name}</Card.Title>
                <Card.Subtitle className="fw-bold mb-4" style={{ color: 'white', fontSize: '0.875rem' }}>{review.location}</Card.Subtitle>
                <Card.Text className='text-font text-start' style={{ color: 'white', fontSize: '0.875rem', lineHeight: '1.15' }} >
                  {review.review}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Review;