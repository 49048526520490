import React from 'react';
import { Row, Col } from 'react-bootstrap';

const Ways = () => {
  const images = [
    { src: './images/zelle.png', alt: '1' },
    { src: './images/visa.png', alt: '2' },
    { src: './images/masterc.png', alt: '3' },
    { src: './images/paypal.png',  alt: '4' },
  ];

  return (
    <div className="text-center my-auto bg-ways">
      <h1 className="mb-4 text-font pt-5 font-ways">MEDIOS DE PAGO</h1>
      <Row className='pb-5 g-0'>
        {images.map((image, index) => (
          <Col key={index} sm={12} md={3} className='my-4'>
            <img src={image.src} alt={image.alt} className="image-ways" />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Ways;
