import React from 'react';
import propTypes from 'prop-types';
import '../../Blog.css';

/**
 * Renders a list of text items with a number for each item.
 *
 * @param {{ id: number; text: string; sub_text?: { id: number; text: string }[] }[]} body
 */
const PostInfoListNumber = ({ body }) => (
    <ol className="mx-2">
        {body.map(({ id, text, sub_text }) => (
            <li key={id} className="post-p">
                {text}
                {sub_text ? (
                    <ul className="list-circle">
                        {sub_text.map(({ id: subId, text: sub_textItem }) => (
                            <li key={subId}>{sub_textItem}</li>
                        ))}
                    </ul>
                ) : null}
            </li>
        ))}
    </ol>
);


export default PostInfoListNumber;

PostInfoListNumber.propTypes = {
    body: propTypes.arrayOf(
        propTypes.shape({
            id: propTypes.number.isRequired,
            text: propTypes.string.isRequired,
            sub_text: propTypes.arrayOf(
                propTypes.shape({
                    id: propTypes.number.isRequired,
                    text: propTypes.string.isRequired
                })
            )
        }),
    ).isRequired
}
