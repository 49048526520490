import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import Step from '../components/home/Step';

const Gracias = () => {


  useEffect(() => {
    if (window.gtag) {
      window.gtag('event', 'conversion', { 'send_to': 'AW-796386786/E9xkCL-UmZwZEOLL3_sC' });
    }
  }, []);
  return (
    <>

      <div className="text-center text-font degraded-background2 " style={{ padding: '12%' }}>
        <Row>
          <Col xs={12} md={12}>
            <h1 className='mt-5 px-auto '>¡Gracias! hemos recibido la informacion. </h1>
            <h3 className='mb-5 px-auto'>Pronto lo contactaran los transportistas para que usted escoja la mejor opcion. </h3>
            <div className='d-flex justify-content-center my-5'>
              <a href='/' className="btn btn-primary custom-color-btn font-f me-md-5 me-sm-4">Volver a la pagina principal</a>
              <a href='tel:+18704556742' className="font-f py-2 btn btn-outline-secondary custom-color-btn" >CONTACTAR AL <span className='font-f fw-bold t-200'>(1) 870-455-6742</span></a>

            </div>
          </Col>

        </Row>
      </div>
      <Step></Step>

    </>
  );
}

export default Gracias;