import React from "react";
import { Row, Col, Card, Button } from "react-bootstrap";

const Step = () => {
  const stepsData = [
    {
      src: "/images/step1.svg",
      title: "Cotiza",
      description:
        "Diligencia todos los campos y envía tu cotización desde la parte superior de esta página o llámanos.",
    },
    {
      src: "/images/step2.svg",
      title: "Compara",
      description:
        "Recibe las cotizaciones de los transportistas compitiendo por clientes.",
    },
    {
      src: "/images/step3.svg",
      title: "Decide",
      description:
        "Selecciona la cotización más conveniente por precio o servicio, y contrata el servicio directamente con la empresa.",
    },
  ];

  return (
    <div className="degraded-background2" id="us">
      <Row className="justify-content-center g-0 p-md-5">
        <Col md={6} className="text-font text-center-right py-5 px-6">
          <p className="mt-3 t-200 mb-0">Transporte terrestre</p>
          <h1 className="w-100 mb-3 mt-0 pe-md-5" style={{ fontSize: "clamp(2.1em, 1vw, 2.5em)", fontWeight: "bold", lineHeight: "1em", textWrap: "balance" }}>
            ENCUENTRA AL <br /> TRANSPORTISTA <br /><span className="t-700">IDEAL</span>
          </h1>
          <p style={{ fontSize: "clamp(0.8em, 1vw, 1em)" }}>Comparativa de Servicios para tu Carro</p>
          <Button href='#form' className="custom-color-btn bg-700 border-0 font-f mb-3 d-block d-md-none w-100 py-3">
            COTIZAR AHORA
          </Button>
          <a href="tel:+18704556742"
            className="btn btn-primary text-white font-f mb-3 py-3 px-md-5 w-100 w-md-auto"
          >
            Llámanos al <span className="font-f fw-bold">(1) 870-455-6742</span>
          </a>
        </Col>
        <Col md={6} className="pt-3 px-6">
          {stepsData.map((step, index) => (
            <Card
              key={index}
              className="step-container mb-3 bg-cardreview-step text-font "
            >
              <Row className="g-0 align-items-center">
                <Col
                  xs={12}
                  md={2}
                  className="mb-2 mb-md-0 my-1 py-md-3 pt-3 ps-4 pe-2 d-flex align-items-center"
                >
                  <Card.Img
                    className="w-img"
                    src={step.src}
                    objectFit="contain"
                    alt={`Step ${index + 1}`}
                  />
                </Col>
                <Col xs={12} md={10}>
                  <Card.Body className="text-font ps-md-0 ps-4">
                    <Card.Title as="p" style={{ color: "white", fontWeight: "bold", lineHeight: "1.2em" }}>
                      {step.title}
                    </Card.Title>
                    <Card.Text style={{ color: "white", fontWeight: "lighter", lineHeight: "1.2em" }}>
                      {step.description}
                    </Card.Text>
                  </Card.Body>
                </Col>
              </Row>
            </Card>
          ))}
        </Col>
      </Row>
    </div>
  );
};

export default Step;
