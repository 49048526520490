import React from 'react';

const Blocked = () => {
  return (
    <div className="d-block d-md-none text-center my-5 px-5">
      <h1 className='text-font text-justify fw-bold'>LOS TRANSPORTISTAS COMPITEN, <span className='rt-200'>ELIGE EL MEJOR</span> PRECIO Y SERVICIO.</h1>
      <div className='d-flex justify-content-center px-md-5 flex-column' >
            <a href='#form' className="btn text-white bg-700 font-f w-100 my-3" >COTIZAR AHORA</a>
            <a href='tel:+18704556742' className="font-f my-2 py-2 btn btn-outline-secondary custom-color-btn " >CONTACTAR AL <span className='font-f fw-bold t-200'>(1) 870-455-6742</span></a>
      </div>
    </div>
  );
};

export default Blocked;
