import React from "react";
import { useNavigate } from "react-router-dom";
import propTypes from 'prop-types';
import { Button, Image } from 'react-bootstrap';


// TODO: revisar clases de estilo
const PostNav = ({ navItems }) => {
  const navigate = useNavigate();

  const handleNavClick = (url) => {
    navigate(url);
  };
  return (
    <>
      <div className='d-flex justify-content-between' id='postNav'>
        {navItems.map((item) => (
          <Button key={item.id}
            className="post-p-small text-decoration-none"
            variant="link"
            style={{ cursor: 'pointer', marginBottom: '0' }}
            onClick={() => handleNavClick(item.url)}><strong>{item.title}</strong></Button>
        ))}
 
          <Image
            src="/images/blog/assets/arrow_right.svg"
            alt="next"
            width="20"
            height="20"
            className="d-inline-block"
            style={{ cursor: 'pointer', marginBottom: '0' }}
            onClick={() => handleNavClick('/blog')}
          />
        
      </div>
      <hr className='divider-solid mb-5' />
    </>
  );
};

export default PostNav;

PostNav.propTypes = {
  navItems: propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.number.isRequired,
      title: propTypes.string.isRequired,
      url: propTypes.string.isRequired
    }),
  ).isRequired
}