import React from 'react';
import { Row, Col } from 'react-bootstrap';


const Contacto = () => {
  return (
    <div className="bg-dark2 d-none d-md-block " id='contact'> 
      <Row className="justify-content-center py-5 g-0">
        <Col md={10} className="text-center " >
          <h1 style={{
            fontFamily: 'Libre Franklin,'
            , fontWeight: 800,
              }}>
                "<span className='t-700 py-5'>TU CARRO EN LAS MEJORES MANOS:</span> ¡SELECCIONA <wbr /> ENTRE LOS TRANSPORTISTAS MAS CONFIABLES<wbr /> CON NUESTRA AYUDA!"
              </h1>
          <div className="my-5 pt-2 d-flex justify-content-center text-center gap-4">
            {/* Botón con color definido en CSS */}
            <a className="custom-color-btn btn bg-700 border-0 fw-bold font-f me-4 py-3 text-white d-flex align-items-center " style={{ textDecoration: 'none', paddingInline: '4.5rem' }} href='#form'>COTIZAR AHORA</a>
            {/* Botón transparente con bordes redondeados */}
            <a href='tel:+18704556742' className="btn btn-outline-light py-3 custom-color-btn font-f px-3  d-flex align-items-center" style={{ textDecoration: 'none' }}>LLÁMANOS AL <span className='font-f fw-bolder custom-color-btn'>(1) 870-455-6742</span></a>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Contacto;
