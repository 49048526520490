import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import StepOne from '../Form/StepOne';
const Cotizar = () => {
  return (
    <div className='bg-home d-flex align-items-md-center justify-content-md-center align-items-end' id='form' style={{
      background: `
          linear-gradient(180deg, rgba(4, 2, 29, 0) 0%, #04021D 80%), 
          url('/images/hero_background.png')
        `,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      objectFit: 'fill',
      minHeight: 'clamp(50vh, 70vh, 145vh)',
      margin: 0,
      padding: 0
    }}>
      <Row className="d-flex d-md align-items-center g-0 "> {/* Added g-0 to remove gutters */}
        <Col md={7} className="ps-md-5 pe-md-0 px-5"> {/* Remove horizontal padding on md screens */}
          <h1 style={{
            fontFamily: 'Libre Franklin, sans-serif',
            fontWeight: 800,
            fontSize: 'clamp(2.5rem, 5vw, 5.25rem)',
            lineHeight: '1em',
            color: '#FFFFFF',
            position: 'relative',
            zIndex: 2,
            marginTop: '8rem',
          }}>
            TRANSPORTE<wbr /> SEGURO PARA<wbr /> TU <span className='t-700'>VEHÍCULO</span>
          </h1>
          <p style={{
            fontSize: 'clamp(1rem, 2vw, 1.25rem)',
            lineHeight: '1.2em',
            fontWeight: 'normal',
            color: '#FFFFFF',
            position: 'relative',
            zIndex: 2,
            marginTop: 'clamp(1rem, 1vw, 1.25rem)',
          }}>
            ¡Compara con Nosotros y Toma Decisiones Inteligentes!
          </p>
        </Col>
        <Col md={5} className="p-5 d-none d-md-block"> {/* Remove horizontal padding on md screens */}
          <Card style={{
            minHeight: '500px',
            background: "rgba(2, 15, 38, 0.85)",
            backdropFilter: "blur(2px)",
            borderRadius: '12px'
          }}
            className='p-5'
          >

            <StepOne />

          </Card>
        </Col>
      </Row>

    </div>
  );
};

export default Cotizar; 